<template>
  <!-- 填写样式组件 -->
  <div :class="['fieldMain', { mainFlex: fieldInfo.formatType > 8 }]">
    <template v-if="fieldInfo.formatType == 1">
      <van-field
        v-model="fieldInfo.value"
        name="formatType1"
        :label="fieldInfo.templateFiledName"
        :placeholder="fieldInfo.templateValue"
        :required="fieldInfo.fillFlag == 1"
        :disabled="disabled"
        clearable
      />
    </template>
    <template v-if="fieldInfo.formatType == 2">
      <van-field
        v-model="fieldInfo.value"
        name="formatType2"
        :label="fieldInfo.templateFiledName"
        :placeholder="fieldInfo.templateValue"
        :required="fieldInfo.fillFlag == 1"
        :disabled="disabled"
        rows="1"
        autosize
        type="textarea"
        clearable
      />
    </template>
    <template v-if="fieldInfo.formatType == 3">
      <van-field
        readonly
        clickable
        name="formatType3"
        v-model="fieldInfo.value"
        :label="fieldInfo.templateFiledName"
        :placeholder="
          Array.isArray(fieldInfo.templateValue)
            ? '请选择'
            : fieldInfo.templateValue
        "
        :required="fieldInfo.fillFlag == 1"
        :disabled="disabled"
        @click="toastEvent('formatType3')"
        clearable
      />
    </template>
    <template v-if="fieldInfo.formatType == 4">
      <van-field
        class="extends"
        readonly
        clickable
        name="formatType4"
        :value="fieldInfo.showVal"
        :label="fieldInfo.templateFiledName"
        :placeholder="
          Array.isArray(fieldInfo.templateValue)
            ? '请选择'
            : fieldInfo.templateValue
        "
        :required="fieldInfo.fillFlag == 1"
        :disabled="disabled"
        @click="toastEvent('showVal')"
      />
      <!-- <van-field
        class="extends"
        readonly
        clickable
        name="formatType4"
        v-model="fieldInfo.showVal"
        :label="fieldInfo.templateFiledName"
        :placeholder="
          Array.isArray(fieldInfo.templateValue)
            ? '请选择'
            : fieldInfo.templateValue
        "
        :required="fieldInfo.fillFlag == 1"
        :disabled="disabled"
      />
      <van-radio-group
        v-model="fieldInfo.value"
        direction="horizontal"
        @change="freshData"
      >
        <van-radio
          v-for="(item, index) in fieldInfo.templateValue"
          :key="index"
          :label="item"
          :name="item"
          >{{ item }}</van-radio
        >
      </van-radio-group> -->
    </template>
    <template v-if="fieldInfo.formatType == 5">
      <van-field
        class="extends"
        readonly
        clickable
        name="formatType5"
        :value="fieldInfo.showVal"
        :label="fieldInfo.templateFiledName"
        placeholder="请选择"
        :required="fieldInfo.fillFlag == 1"
        :disabled="disabled"
        @click="toastEventCheckGroup('showVal5')"
      />
      <!-- <van-field
        class="extends"
        readonly
        clickable
        name="formatType5"
        v-model="fieldInfo.showVal"
        :label="fieldInfo.templateFiledName"
        placeholder="请选择"
        :required="fieldInfo.fillFlag == 1"
        :disabled="disabled"
      />
      <van-checkbox-group
        v-model="fieldInfo.value"
        direction="horizontal"
        @change="freshData"
      >
        <van-checkbox
          shape="square"
          v-for="(item, index) in fieldInfo.templateValue"
          :key="index"
          :name="item"
          >{{ item }}</van-checkbox
        >
      </van-checkbox-group> -->
    </template>
    <template v-if="fieldInfo.formatType == 6">
      <!-- 年月 -->
      <van-field
        readonly
        clickable
        name="formatType6"
        v-model="fieldInfo.showVal"
        :label="fieldInfo.templateFiledName"
        placeholder="请选择年月"
        :required="fieldInfo.fillFlag == 1"
        :disabled="disabled"
        @click="toastEvent('yearMonth')"
      />
    </template>
    <template v-if="fieldInfo.formatType == 7">
      <!-- 年月日 -->
      <van-field
        readonly
        clickable
        name="formatType7"
        v-model="fieldInfo.showVal"
        :label="fieldInfo.templateFiledName"
        placeholder="请选择年月日"
        :required="fieldInfo.fillFlag == 1"
        :disabled="disabled"
        @click="toastEvent('yearMonthDay')"
      />
    </template>
    <template v-if="fieldInfo.formatType == 8">
      <van-field
        readonly
        class="extends"
        :clickable="false"
        name="formatType8"
        v-model="fieldInfo.showVal"
        :label="fieldInfo.templateFiledName"
        :required="fieldInfo.fillFlag == 1"
        :disabled="disabled"
      >
      <!-- <template #button>
        <van-button
        icon="plus"
        class="addBtn"
        @click="addRow"
        size="mini"
        type="info"
        >添加项</van-button
      >
      </template> -->
      <template #button>
        <span @click="addRow" class="addBtn">点击添加</span>
      </template>
    </van-field>
    <!-- <van-field
        readonly
        class="extends"
        :clickable="false"
        label="添加组"
      >
      <template #button>
        <span @click="addRow" class="addBtn">点击添加</span>
      </template>
    </van-field> -->
      <!-- <span @click="addRow" class="addBtn">+添加项</span> -->
      <el-table
        :data="fieldInfo.value"
        height="250"
        border
        size="small"
        header-row-class-name="rowHeader"
        style="width: 100%"
      >
        <el-table-column
          v-for="(item, index) in fieldInfo.templateValue"
          :key="index"
        >
        <!-- :prop="'column' + (index + 1)"
        :label="item" -->
          <template slot-scope="scope">
            <div class="cellBox">
              <div class="inputBox">
                <van-field
                  clickable
                  @keyup.native.enter="preventSubmit"
                  v-model="fieldInfo.value[scope.$index]['column' + (index + 1)]"
                  @change="freshData"
                />
              </div>
              <div v-if="fieldInfo.value.length > 1" class="delBox" @click="delRow(scope.$index)">
                <van-icon name="delete-o" />
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <template v-if="fieldInfo.formatType == 9">
      <van-field
        ref="proviceField"
        class="proviceField"
        clickable
        name="formatType9"
        v-model="fieldInfo.showVal"
        :label="fieldInfo.templateFiledName"
        :placeholder="fieldInfo.templateValue"
        :required="fieldInfo.fillFlag == 1"
        :disabled="disabled"
        @click="toastEvent('provice')"
      />
    </template>
    <template v-if="fieldInfo.formatType == 10">
      <van-field
        clickable
        name="formatType10"
        v-model="fieldInfo.showVal"
        :label="fieldInfo.templateFiledName"
        :placeholder="fieldInfo.templateValue"
        :required="fieldInfo.fillFlag == 1"
        :disabled="disabled"
        @click="toastEvent('provice')"
      />
    </template>
    <van-popup class="fielPopup" v-model="showPicker" position="bottom">
      <van-cascader
        v-if="pickname === 'provice'"
        v-model="fieldInfo.showVal"
        title="请选择籍贯"
        :options="proviceData"
        @close="showPicker = false"
        @change="onChange"
        @finish="onFinish"
      >
      </van-cascader>
      <van-datetime-picker
        v-else-if="pickname === 'yearMonth'"
        v-model="fieldInfo.value"
        type="year-month"
        title="选择年月"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="onConfirm"
      >
        <template slot="cancel">
          <span class="popupSlotCancel">取消</span>
        </template>
        <template slot="title">
          <span class="popupSlotTitle">选择年月</span>
        </template>
        <template slot="confirm">
          <span class="popupSlotConfirm">确认</span>
        </template>
      </van-datetime-picker>
      <van-datetime-picker
        v-model="fieldInfo.value"
        v-else-if="pickname === 'yearMonthDay'"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="onConfirm"
      >
        <template slot="cancel">
          <span class="popupSlotCancel">取消</span>
        </template>
        <template slot="title">
          <span class="popupSlotTitle">选择年月日</span>
        </template>
        <template slot="confirm">
          <span class="popupSlotConfirm">确认</span>
        </template>
      </van-datetime-picker>
      <van-picker
        v-else
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <van-popup class="fielPopup" v-model="showPickerGroup" position="bottom" @close="onCancel">
      <div class="popup-header">
        <span class="cancel" @click="onCancel">取消</span>
        <span class="title">请选择(多选)</span>
        <span class="confirm" @click="onConfirmC">确认</span>
      </div>
      <div class="options-list">
        <!-- fieldInfo.templateValue -->
        <div
          v-for="option in fieldInfo.templateValue"
          :key="option"
          :class="{ selected: tempSelectedItems.includes(option),item: true }"
          @click="toggleSelection(option)"
        >
          {{ option }}
          <img class="img" src="@/assets/img/selectedBg.png" alt="" srcset="">
        </div>
      </div>
      <!-- <van-picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showPickerGroup = false"
      /> -->
    </van-popup>
  </div>
</template>

<script>
import { ImagePreview, Toast, DatetimePicker } from 'vant'
export default {
  name: 'field',
  components: { DatetimePicker },
  props: {
    info: {
      type: Object, Array
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    info: {
      immediate: true,
      handler (newVal, oldVal) {
        this.fieldInfo = newVal
        this.initInfo()
      }
    }
  },
  data () {
    return {
      tempSelectedItems: [], // 临时选择的项目
      fieldInfo: {},
      formInline: {},
      proviceData: [],
      citiesData: [],
      regionData: [],
      verifying: false,
      pickname: null,
      showPicker: false,
      showPickerGroup: false,
      isOrObj: {
        0: '否',
        1: '是'
      },
      minDate: new Date(2010, 0, 1),
      maxDate: new Date(2030, 10, 1),
      columns: []
    }
  },
  created () {
    // this.fieldInfo = this.info
    // this.initInfo()
  },
  methods: {
    toggleSelection (id) {
      const index = this.tempSelectedItems.indexOf(id)
      if (index === -1) {
        this.tempSelectedItems.push(id)
      } else {
        this.tempSelectedItems.splice(index, 1)
      }
    },
    onCancel () {
      // 取消选择，恢复到已选择的数据
      this.tempSelectedItems = [...this.fieldInfo.value]
      this.showPickerGroup = false
    },
    onConfirmC () {
      // 确认选择，更新已选择的数据
      this.fieldInfo.value = [...this.tempSelectedItems]
      const typeArr = [5]
      console.log(this.fieldInfo.templateValue)
      if (
        this.fieldInfo.templateValue &&
        typeArr.includes(this.fieldInfo.formatType)
      ) {
        // this.fieldInfo.showVal = this.fieldInfo.value.join(',')
        this.$set(this.fieldInfo, 'showVal', this.fieldInfo.value.join(','))
        this.showPickerGroup = !this.showPickerGroup
      }
      this.$emit('freshData', this.fieldInfo)
    },
    formatter (type, val) {
      console.log(type, 'type', val)
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      return val
    },
    toastEventCheckGroup (name) {
      this.showPickerGroup = !this.showPickerGroup
    },
    toastEvent (name) {
      if (this.firstDisabled(name)) return
      this.pickname = name
      let arr = []
      switch (name) {
        case 'nation':
          arr = this.$common.nationData.map((item) => item.name)
          this.columns = arr
          break
        case 'politicsStatus':
          arr = Object.values(this.$common.politicsData)
          this.columns = arr
          break
        case 'provice':
          arr = Object.values(this.proviceData)
          console.log(this.proviceData, 'proviceData')
          this.columns = arr
          break
        case 'formatType3':
          console.log(this.fieldInfo, 'fieldInfo')
          arr = this.fieldInfo.templateValue
          this.columns = arr
          break
        case 'showVal':
          console.log(this.fieldInfo, 'fieldInfo')
          arr = this.fieldInfo.templateValue
          this.columns = arr
          break
        default:
          this.columns = Object.values(this.isOrObj)
          break
      }
      console.log(this.columns)
      this.showPicker = !this.showPicker
    },
    preventSubmit (event) {
      console.log('huiche')
      // 阻止回车键触发表单提交
      event.preventDefault()
    },
    onConfirm (value, index) {
      console.log(value, index, 'onConfirm')
      // this.value = value
      const isOrList = [
        'overseasChineseFlag',
        'specialAdministrativeRegionFlag',
        'disabilityFlag'
      ]
      if (isOrList.includes(this.pickname)) {
        this.fieldInfo.value = index
      } else this.fieldInfo.value = value

      const typeArr = [5]
      console.log(this.fieldInfo.templateValue)
      if (
        this.fieldInfo.templateValue &&
        typeArr.includes(this.fieldInfo.formatType)
      ) {
        // this.fieldInfo.showVal = this.fieldInfo.value.join(',')
        this.$set(this.fieldInfo, 'showVal', this.fieldInfo.value.join(','))
      }
      if (this.fieldInfo.formatType == 6 || this.fieldInfo.formatType == 7) {
        let dateShow =
          this.fieldInfo.value.getFullYear() +
          '-' +
          (this.fieldInfo.value.getMonth() + 1)
        if (this.fieldInfo.formatType == 7) {
          dateShow =
            this.fieldInfo.value.getFullYear() +
            '-' +
            (this.fieldInfo.value.getMonth() + 1) +
            '-' +
            this.fieldInfo.value.getDate()
        }
        this.$set(this.fieldInfo, 'showVal', dateShow)
      }

      this.showPicker = false
      this.freshData()
    },
    initInfo () {
      const typeArr = [3, 4, 5, 8]
      console.log(this.fieldInfo.templateValue)
      if (
        this.fieldInfo.templateValue &&
        typeArr.includes(this.fieldInfo.formatType) &&
        !Array.isArray(this.fieldInfo.templateValue)
      ) {
        const arr = this.fieldInfo.templateValue.split('|')
        this.fieldInfo.templateValue = arr
      }
      if (this.fieldInfo.formatType == 4 && this.fieldInfo.value) {
        this.$set(this.fieldInfo, 'showVal', this.fieldInfo.value)
      } else {
      }
      if (this.fieldInfo.formatType == 5) {
        if (Array.isArray(this.fieldInfo.value)) {
          this.$set(this.fieldInfo, 'showVal', this.fieldInfo.value.join(','))
        } else {
          this.$set(this.fieldInfo, 'value', [])
        }
      }
      if (this.fieldInfo.formatType == 6 || this.fieldInfo.formatType == 7) {
        if (this.fieldInfo.value) {
          const date = new Date(Number(this.fieldInfo.value))
          let showTime = date.getFullYear() + '-' + (date.getMonth() + 1)
          if (this.fieldInfo.formatType == 7) {
            showTime = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
          }
          this.$set(this.fieldInfo, 'showVal', showTime)
          this.$set(this.fieldInfo, 'value', date)
        } else {
          this.$set(this.fieldInfo, 'value', new Date())
        }
      }
      if (this.fieldInfo.formatType == 8) {
        if (!this.fieldInfo.value) {
          this.fieldInfo.value = [{}]
          this.fieldInfo.templateValue.forEach((item, idx) => {
            // this.fieldInfo.value[0]['column'+(Number(idx)+1)] = ""
            this.$set(
              this.fieldInfo.value[0],
              'column' + (Number(idx) + 1),
              ''
            )
          })
          console.log(this.fieldInfo.value)
        } else {
          this.$set(this.fieldInfo, 'showVal', '已填写')
        }
      }
      if (this.fieldInfo.formatType > 8) {
        this.getProviceEvent()
        if (this.fieldInfo.value) {
          this.$set(this.fieldInfo, 'showVal', this.fieldInfo.value.join('/'))
        } else {
          this.fieldInfo.value = ['', '', '']
        }
      }
      const showArr = [4, 5, 8]
    },
    addRow () {
      console.log('18A6F3')
      if (this.fieldInfo.value.length < 5) {
        this.fieldInfo.value.push({})
      }
    },
    delRow (index) {
      this.fieldInfo.value.splice(index, 1)
    },
    getInfoProvice () {
      this.formInline.nativePlace =
        this.formInline.provice + this.formInline.city
    },
    // 获取省份
    getProviceEvent () {
      this.$request.getProviceNew().then((res) => {
        if (res.data.code == 0) {
          this.proviceData = res.data.data.map((item) => {
            return {
              ...item,
              text: item.name,
              value: item.name,
              children: []
            }
          })
        }
      })
    },
    freshData () {
      console.log(this.fieldInfo, 'fieldInfo')
      const typeArr1 = [4, 8]
      const typeArr = [5]
      console.log(this.fieldInfo.templateValue)
      if (
        this.fieldInfo.templateValue &&
        typeArr.includes(this.fieldInfo.formatType)
      ) {
        // this.fieldInfo.showVal = this.fieldInfo.value.join(',')
        this.$set(this.fieldInfo, 'showVal', this.fieldInfo.value.join(','))
        this.showPickerGroup = !this.showPickerGroup
      }
      if (this.fieldInfo.value && this.fieldInfo.formatType == 4) {
        this.$set(this.fieldInfo, 'showVal', this.fieldInfo.value)
      }
      if (this.fieldInfo.value && this.fieldInfo.formatType == 8) {
        this.$set(this.fieldInfo, 'showVal', '已填写')
      }
      this.$emit('freshData', this.fieldInfo)
    },
    firstDisabled (key) {
      // if (this.studentInfo.confirmStatus == 2) {
      //   return true
      // } else if(this.studentInfo.confirmStatus == 3) {
      //   return false
      // // } else if (this.arrDara.includes(key)) {
      // //   console.log(this.arrData,66)
      // //   return true
      // } else {
      // }
      return false
    },
    onChange ({ value, selectedOptions, tabIndex }) {
      console.log(selectedOptions, 'selectedOptions')
      console.log(value, 'value', tabIndex, this.proviceData[tabIndex].value)
      if (tabIndex === 0 || tabIndex === 1) {
        const obj = {
          name: value
        }
        if (tabIndex === 1 && value.includes('区')) {
          return
        }
        this.$request.getCitiesStu(obj).then((res) => {
          if (res.data.code == 0) {
            this.proviceData.forEach((val, key) => {
              if (val.id == selectedOptions[0].id) {
                if (tabIndex == 1) {
                  this.proviceData[key].children.forEach((city, cityIdx) => {
                    if (city.id == selectedOptions[1].id) {
                      this.proviceData[key].children[cityIdx].children =
                        res.data.data.map((item) => {
                          return {
                            ...item,
                            text: item.name,
                            value: item.name
                          }
                        })
                    }
                  })
                  console.log(this.proviceData[key], 'this.proviceData[key]')
                } else {
                  console.log('0******')
                  this.proviceData[key].children = res.data.data.map((item) => {
                    return {
                      ...item,
                      text: item.name,
                      value: item.name,
                      children:
                        this.fieldInfo.formatType == 10
                          ? !item.name.includes('区')
                            ? []
                            : null
                          : null
                    }
                  })
                  console.log(this.proviceData[key], 'this.proviceData[key]')
                }
              }
            })
          }
        })
      } else {
      }
    },
    onFinish ({ selectedOptions }) {
      this.showPicker = false
      this.fieldInfo.value = selectedOptions
        .map((option) => option.text)
        .join('|')
      this.fieldInfo.showVal = selectedOptions
        .map((option) => option.text)
        .join('/')
      this.freshData()
    }
  }
}
</script>

<style lang="scss" scoped>
.fieldMain {
  background: #ffffff;
}
.extends::after {
  border-bottom-color: #ffffff;
}
.van-radio-group,
.van-checkbox-group {
  background: #ffffff;
  padding: 10px 15px;
  position: relative;
}
.van-radio-group::after,
.van-checkbox-group::after {
  position: absolute;
  box-sizing: border-box;
  content: " ";
  pointer-events: none;
  right: 0;
  bottom: 0;
  left: 0;
  border-bottom: 0.026667rem solid #D2D2D2;
  -webkit-transform: scaleY(0.5);
  transform: scaleY(0.5);
}
.addBtn {
  font-family: PingFang SC;
  font-weight: 500;
  font-size: 12px;
  color: #FFFFFF;
  background: #3870FF;
  border-radius: 20px;
  padding: 4px 10px;
}
::v-deep .el-table {
  height: auto !important;
  th {
    padding: 0px;
  }
}
::v-deep .el-table__body-wrapper {
  height: auto !important;
}
.pub_select {
  width: 110px;
}
.mainFlex {
  display: flex;
}
.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
  background-color: transparent;

}
.el-table__row {
  td {
    .cellBox {
      display: flex;
      justify-content: space-between;
      .inputBox {
        flex: 1;
        background-color: rgba(237, 238, 242, .62);
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 12px;
        color: #333333;
      }
      .delBox {
        margin: 12px 11px;
      }
    }
  }
}

.popupSlotCancel {
  font-family: PingFang SC;
  font-weight: 500;
  font-size: 15px;
  color: #666666;
}
.popupSlotTitle {
  font-family: PingFang SC;
  font-weight: bold;
  font-size: 19px;
  color: #333333;
}
.popupSlotConfirm {
  font-family: PingFang SC;
  font-weight: 500;
  font-size: 15px;
  color: #3869F5;
}

.popup-header  {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 19px;
  border-bottom: 1px solid #D2D2D2;
  .cancel {
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 15px;
    color: #666666;
  }
  .title {
    font-family: PingFang SC;
    font-weight: bold;
    font-size: 19px;
    color: #333333;
  }
  .confirm {
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 15px;
    color: #3869F5;
  }
}
.options-list {
  min-height: 240px;
  max-height: 500px;
  overflow: hidden auto;
  display: flex;
  flex-wrap: wrap;
  gap: 14px 26px;
  padding: 20px 29px;
}
.options-list div {
  width: 88px;
  height: 28px;
  background: #F3F3F3;
  border: 1px solid #F3F3F3;
  border-radius: 6px;
  font-family: PingFang SC;
  font-weight: 500;
  font-size: 12px;
  color: #666666;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  .img {
    visibility: hidden;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 10px;
    height: 10px;
  }
}

.options-list div.selected {
  background: #ECF1FF;
  border: 1px solid #3869F5;
  font-family: PingFang SC;
  font-weight: 500;
  font-size: 12px;
  color: #3869F5;
  // background: url("../../assets/img/selectedBg.png") no-repeat;
  // background-position: bottom right;
  // background-size: 13%;
  .img {
    visibility: visible;
  }
}

</style>
