<template>
  <div class="studentStatusConfirm">
    <div class="topBg"></div>
    <div style="position: relative;">
      <van-notice-bar
        v-if="enroll"
        left-icon="info-o"
        text="以下不可更改的信息若有误请联系学校老师"
      />
      <div class="stepper">
        <div
          v-for="(step, index) in steps"
          :key="index"
          class="step-container"
        >
          <div
            class="step"
            :class="{ active: index === activeStep, completed: index < activeStep }"
          >
            <div class="topbox">
              <div class="circle" :class="index >  activeStep ? 'endcircle' : ''">
              </div>
            </div>
            <div class="label">{{ step.label }}</div>
          </div>
          <div
            v-if="index < steps.length - 1"
            class="lineBox"
            :class="{ completed: index < activeStep}"
          >
            <div class="topbox">
              <div class="line" :class="{endcircle: index === steps.length -1,  line2: steps.length == 2 }">
              </div>
            </div>
            <div class="label">{{ step.label }}</div>
          </div>
        </div>
      </div>
      <div v-show="activeStep === 0" class="infoModule-cont">
        <van-form
          disabled
          class="audit-area"
          v-if="!enroll && studentInfo.confirmStatus"
        >
        <div class="formItemBox">
            <div class="formItemTitle">
              审核信息
            </div>
            <div class="formItemContent">
              <van-field
                v-model="confirmStatusShow"
                label="审核状态"
                placeholder="审核状态"
              />
              <van-field
                v-model="studentInfo.confirmAuditOpinion"
                autosize
                label="审核意见"
                type="textarea"
                placeholder="审核意见"
              />
            </div>
          </div>

        </van-form>
        <van-form
          :submit-on-enter="false"
          @submit="submitEvent"
          @failed="failedEvent"
        >
          <div class="formItemBox">
            <div class="formItemTitle">
              人员信息
            </div>
            <div class="formItemContent">
              <van-field
                v-model="studentInfo.name"
                name="真实姓名"
                label="真实姓名"
                placeholder="真实姓名"
                disabled
              />
              <van-field
                v-model="studentInfo.formerName"
                name="曾用名"
                label="曾用名"
                placeholder="曾用名"
                :disabled="firstDisabled('formerName')"
              />
              <van-field
                v-model="$common.sexList[studentInfo.sex]"
                name="性别"
                label="性别"
                placeholder="性别"
                disabled
              />
              <van-field
                required
                readonly
                clickable
                name="picker"
                :value="studentInfo.nation"
                label="民族"
                placeholder="民族"
                @click="toastEvent('nation')"
                :rules="[{ required: true, message: '请选择' }]"
                :disabled="firstDisabled('nation')"
              />
              <van-field
                required
                readonly
                clickable
                name="picker"
                :value="nativePlace"
                label="籍贯"
                placeholder="省/市/区"
                @click="toastEvent('provice')"
                :rules="[{ required: true, message: '请选择' }]"
                :disabled="firstDisabled('nativePlace')"
              />
              <!-- <van-field
                required
                readonly
                clickable
                name="picker"
                :value="studentInfo.provice"
                label="籍贯"
                placeholder="省/市/区"
                @click="toastEvent('provice')"
                :rules="[{ required: true, message: '请选择' }]"
                :disabled="firstDisabled('nativePlace')"
              />
              <van-field
              required
                readonly
                clickable
                name="picker"
                :value="studentInfo.city"
                label="     "
                placeholder="市/区"
                @click="toastEvent('city')"
                :rules="[{ required: true, message: '请选择' }]"
                :disabled="firstDisabled('nativePlace')"
              /> -->
              <van-field
                required
                readonly
                clickable
                name="picker"
                :value="studentInfo.politicsStatus"
                label="政治面貌"
                placeholder="政治面貌"
                @click="toastEvent('politicsStatus')"
                :rules="[{ required: true, message: '请选择' }]"
                :disabled="firstDisabled('politicsStatus')"
              />
              <van-field
                v-model="studentInfo.cardId"
                name="身份证号"
                label="身份证号"
                placeholder="身份证号"
                disabled
              />
            </div>
          </div>
          <div class="formItemBox">
            <div class="formItemTitle">
              工作信息
            </div>
            <div class="formItemContent">
              <van-field
                required
                v-model="studentInfo.unit"
                name="工作单位"
                label="工作单位"
                placeholder="工作单位"
                maxlength="40"
                :rules="[{ required: true, message: '请输入' }]"
                :disabled="firstDisabled('unit')"
              />
              <van-field
                required
                v-model="studentInfo.domicile"
                name="户籍地址"
                label="户籍地址"
                placeholder="户籍地址"
                maxlength="40"
                :rules="[{ required: true, message: '请输入' }]"
                :disabled="firstDisabled('domicile')"
              />
              <van-field
                required
                v-model="studentInfo.address"
                name="通讯地址"
                label="通讯地址"
                placeholder="通讯地址"
                maxlength="40"
                :rules="[{ required: true, message: '请输入' }]"
                :disabled="firstDisabled('address')"
              />
              <van-field
                required
                v-model="studentInfo.email"
                name="电子邮箱"
                label="电子邮箱"
                placeholder="电子邮箱"
                maxlength="40"
                :rules="[{ required: true, message: '请输入' }]"
                :disabled="firstDisabled('email')"
              />
              <van-field
                required
                v-model="studentInfo.phone"
                name="手机号码"
                label="手机号码"
                placeholder="手机号码"
                maxlength="20"
                :rules="[{ required: true, message: '请输入' }]"
                v-if="studentInfo.studentConfirmPhoneFlag != 1"
                :disabled="firstDisabled('phone')"
              />
              <van-field
                required
                v-model="studentInfo.bindPhone"
                name="手机号码"
                label="手机号码"
                placeholder="手机号码"
                maxlength="20"
                :rules="[{ required: true, message: '请输入' }]"
                v-if="studentInfo.studentConfirmPhoneFlag == 1"
                :disabled="firstDisabled('bindPhone')"
              />
              <van-field
                required
                v-model="studentInfo.code"
                name="验证码"
                label="验证码"
                placeholder="验证码"
                maxlength="8"
                :rules="[{ required: true, message: '请输入' }]"
                :disabled="firstDisabled('code')"
                v-if="studentInfo.studentConfirmPhoneFlag == 1"
              >
                <template #button>
                  <van-button
                    size="small"
                    round
                    type="info"
                    :disabled="isDisabled || firstDisabled('code')"
                    @click="getVerify"
                    >{{ btnText }}</van-button
                  >
                </template>
              </van-field>
              <van-field
                v-if="enroll"
                required
                v-model="studentInfo.emergencyPhone"
                name="紧急联系人"
                label="紧急联系人"
                placeholder="紧急联系人"
                maxlength="20"
                :rules="[{ required: true, message: '请输入' }]"
                :disabled="firstDisabled('emergencyPhone')"
              />
              <van-field
                required
                readonly
                clickable
                name="picker"
                label="是否华侨"
                placeholder="是否华侨"
                :rules="[{ required: true, message: '请选择' }]"
                :disabled="firstDisabled('overseasChineseFlag')"
              >
              <!-- @click="toastEvent('overseasChineseFlag')"-->
                <template #input>
                  <van-radio-group v-model="studentInfo.overseasChineseFlag" direction="horizontal">
                    <van-radio name="1">是</van-radio>
                    <van-radio name="0">否</van-radio>
                  </van-radio-group>
                </template>
              </van-field>
              <van-field
                required
                readonly
                clickable
                name="picker"
                :value="isOrObj[studentInfo.specialAdministrativeRegionFlag]"
                label="是否港澳台"
                placeholder="是否港澳台"
                :rules="[{ required: true, message: '请选择' }]"
                :disabled="firstDisabled('specialAdministrativeRegionFlag')"
              >
              <!-- @click="toastEvent('specialAdministrativeRegionFlag')" -->
                <template #input>
                  <van-radio-group v-model="studentInfo.specialAdministrativeRegionFlag" direction="horizontal">
                    <van-radio name="1">是</van-radio>
                    <van-radio name="0">否</van-radio>
                  </van-radio-group>
                </template>
              </van-field>
              <van-field
                required
                readonly
                clickable
                name="picker"
                :value="isOrObj[studentInfo.disabilityFlag]"
                label="是否残疾"
                placeholder="是否残疾"
                :rules="[{ required: true, message: '请选择' }]"
                :disabled="firstDisabled('disabilityFlag')"
              >
              <!-- @click="toastEvent('disabilityFlag')" -->
                <template #input>
                  <van-radio-group v-model="studentInfo.disabilityFlag" direction="horizontal">
                    <van-radio name="1">是</van-radio>
                    <van-radio name="0">否</van-radio>
                  </van-radio-group>
                </template>
              </van-field>
              <template v-for="(item, index) in infoParamList">
                <Field
                  :info="item"
                  :key="index"
                  :disabled="firstDisabled()"
                  @toastProvice="toastProviceField"
                  @freshData="freshData"
                ></Field>
                <!-- <van-field
                    :key="index"
                    :required="item.fillFlag==1"
                    readonly
                    clickable
                    name="picker"
                    :value="isOrObj[studentInfo.disabilityFlag]"
                    :label="item.templateFiledName"
                    :placeholder="item.templateValue"
                    @click="toastEvent('disabilityFlag')"
                    :rules="[{ required: true, message: '请选择' }]"
                    :disabled="firstDisabled('disabilityFlag')"
                  /> -->
              </template>
            </div>
          </div>

          <van-button
            ref="formbtn"
            style="display: none"
            round
            block
            type="info"
            native-type="submit"
            >提交</van-button
          >
        </van-form>
      </div>
      <div v-show="activeStep === 1" class="infoModule-cont">
        <van-form disabled>
          <div class="formItemBox">
            <div class="formItemTitle">
              学校信息
            </div>
            <div class="formItemContent">
              <van-field
                v-model="studentInfo.studentNum"
                name="学号"
                label="学号"
                placeholder="学号"
              />
              <van-field
                v-model="studentInfo.examNum"
                name="考生号"
                label="考生号"
                placeholder="考生号"
              />
              <van-field
                v-if="!enroll"
                v-model="studentInfo.tenantName"
                name="学校"
                label="学校"
                placeholder="学校"
              />
              <van-field
                v-model="studentInfo.stationName"
                name="教学点"
                label="教学点"
                placeholder="教学点"
              />
              <van-field
                v-model="studentInfo.admissionBatch"
                name="批次"
                label="批次"
                placeholder="批次"
              />
            </div>
          </div>
          <div class="formItemBox">
            <div class="formItemTitle">
              学生信息
            </div>
            <div class="formItemContent">
              <van-field
                v-model="studentInfo.majorName"
                name="专业"
                label="专业"
                placeholder="专业"
              />
              <van-field
                v-model="studentInfo.eduCategoryName"
                name="层次"
                label="层次"
                placeholder="层次"
              />
              <van-field
                v-model="studentInfo.learningSystem"
                name="学制"
                label="学制"
                placeholder="学制"
              />
              <van-field
                v-model="stuStatu"
                name="学籍状态"
                label="学籍状态"
                placeholder="学籍状态"
              />
              <van-field
                v-if="enroll"
                v-model="toTime[0]"
                name="入学时间"
                label="入学时间"
                placeholder="入学时间"
              />
              <van-field
                v-model="
                  $common.getRegistrationStatus[studentInfo.registrationStatus]
                "
                name="注册状态"
                label="注册状态"
                placeholder="注册状态"
              />
              <van-field
                v-if="enroll"
                v-model="studentInfo.tenantName"
                name="毕业院校"
                label="毕业院校"
                placeholder="毕业院校"
              />
              <van-field
                v-if="enroll"
                v-model="toTime[0]"
                name="毕业时间"
                label="毕业时间"
                placeholder="毕业时间"
              />
            </div>
          </div>

        </van-form>
      </div>
      <div v-show="activeStep === 2 && stuCollectInfo.showFlag" class="infoModule-cont">
        <div class="formItemBox">
          <div class="formItemTitle">
            填写信息
          </div>
          <div class="formItemContent">
            <div class="collect-cont">
              <div
                class="collect-item"
                v-for="(item, index) in stuCollectInfo.items"
                :key="index"
              >
                <div class="ci-title">
                  <span>
                    <span style="color: #ff0202">*</span>{{ item.description }}
                  </span>
                  <span @click="downEvent(item.sample)" v-if="item.sample">
                    <img src="@/assets/img/warring.png" alt="" />查看样例
                  </span>
                </div>
                <div class="fc-area">
                  <template v-if="item.urls && item.urls.length != 0">
                    <div
                      class="ke-show"
                      v-for="(ite, ind) in item.urls"
                      :key="ind"
                    >
                      <div class="img-ke items" v-if="$way.isImageType(ite)">
                        <van-image fit="none" :src="ite" />
                        <span></span>
                      </div>
                      <span class="notImg" v-else>
                        <!-- <img src="@/assets/img/fileIcon.png" alt="" /> -->
                        <div>{{ getFileName(ite) }}</div>
                      </span>
                      <div class="zhez">
                        <img
                          class="deleteI"
                          src="@/assets/img/deleteIcon.png"
                          alt=""
                          @click.stop="deleteEvent(index, ind)"
                          v-if="studentInfo.confirmStatus != 2"
                        />
                        <img
                          class="previewI"
                          src="@/assets/img/previewIcon.png"
                          alt=""
                          v-show="$way.isImageType(ite)"
                          @click="previewEvent(ite)"
                        />
                        <!-- <img class="downI" src="@/assets/img/downLoadIcon.png" alt="" @click="downEvent(ite)"> -->
                      </div>
                    </div>
                  </template>
                  <div
                    v-if="isshowFile(item)"
                    class="addImg"
                    @click="handleUploadClick(item, index)"
                  >
                    <!-- <img
                      class="add"
                      src="@/assets/img/stu1.png"
                      alt=""
                      srcset=""
                    /> -->
                    <img
                      class="add"
                      src="@/assets/img/add.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
                <div class="ci-dec">
                  {{ item.prompt }}
                </div>
              </div>
              <div class="collect-tip">
                <p>注意事项</p>
                <p v-html="stuCollectInfo.attention"></p>
              </div>
            </div>
          </div>
        </div>

      </div>
      <!-- <div class="info-btn-area opacity">
        <van-button class="info-btn" round type="default" @click="goBack"
          >返回</van-button
        >
        <van-button
          class="info-btn"
          round
          type="info"
          @click="enterEvent"
          v-if="studentInfo.confirmStatus != 2"
          >确定</van-button
        >
      </div> -->
      <div class="info-btn-area">
        <van-button class="info-btn back" round type="default" @click="goBack"
          >{{ activeStep === 0 ? '返回' : '上一步' }}</van-button
        >
        <van-button
          class="info-btn next"
          round
          type="info"
          @click="enterEvent"
          v-if="studentInfo.confirmStatus != 2"
          >{{ activeStep === steps.length - 1 ? '确定' : '下一步' }}</van-button
        >
      </div>
      <van-popup v-model="showPicker" position="bottom">
        <van-cascader
          v-if="pickname === 'provice'"
          v-model="studentInfo.nativePlace"
          title="请选择籍贯"
          :options="proviceData"
          @close="showPicker = false"
          @change="onChange"
          @finish="onFinish"
        />
        <van-picker
          v-else
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        />
      </van-popup>
      <!-- 上传选择 -->
      <van-action-sheet
        v-model="showUploadWay"
        :actions="actions"
        cancel-text="取消"
        close-on-click-action
        @select="onSelect"
      />

      <!--打开相机-->
      <input
        style="display: none"
        type="file"
        ref="videoDom"
        accept="image/*"
        capture="camera"
        @change="videoChange"
      />

      <!-- 调用系统文件 -->
      <input
        style="display: none"
        type="file"
        ref="fileDom"
        @change="imgChange"
      />
    </div>
  </div>
</template>

<script>
import uploadAli from '@/api/uploadAli.js'
import { ImagePreview, Toast } from 'vant'
import Field from './field.vue'
export default {
  name: 'studentStatusConfirm',
  components: { Field },
  data () {
    return {
      activeStep: 0, // 当前激活的步骤索引
      steps: [
        { label: '基本信息' },
        { label: '学籍信息' }
      ],
      type: null,
      formInline: {
        address: null,
        code: null,
        email: null,
        nation: null,
        phone: null,
        bindPhone: null,
        politicsStatus: null,
        unit: null,
        infos: [],
        emergencyPhone: null,
        nativePlace: null,
        domicile: null,
        overseasChineseFlag: null,
        specialAdministrativeRegionFlag: null,
        disabilityFlag: null
      },
      confirmStatusShow: null,
      studentInfo: {},
      showPicker: false,
      columns: [],
      pickname: null,
      isDisabled: false,
      btnText: '获取验证码',
      arrDara: [],
      stuCollectInfo: [], // 信息采集
      exampleUpIdx: null,
      showUploadWay: false,
      actions: [
        { name: '拍照', value: 'video' },
        { name: '文件上传', value: 'image' }
      ],
      enroll: false,
      studentStatusArr: {
        1: '已录取',
        2: '在读'
      },
      isOrObj: {
        0: '否',
        1: '是'
      },
      proviceData: [],
      citiesData: [],
      nativePlace: '',
      infoParamList: []
    }
  },
  watch: {
    '$store.state.isGetToken' (val) {
      if (val && this.$route.name == 'studentStatusConfirm') {
        this.type = this.$store.state.deviceType
        // 录取学籍确认enroll
        this.enroll = this.$route.query.type == 'enroll'
        if (this.enroll === true) this.getUserDataEnroll()
        else this.getUserData()
        // this.stuInfoCollect()
        this.getProviceEvent()
      }
    }
  },
  created () {
    // sessionStorage.setItem('token', 'f42d2bd5-713f-4cb9-8e4e-c5be6b5f0bb3')
    // sessionStorage.setItem('tenant_id', 223)
    // this.enroll = this.$route.query.type = !!'enroll'
    // if (this.enroll) this.getUserDataEnroll()
    // else this.getUserData()
  },
  computed: {
    stuStatu () {
      return this.enroll
        ? this.studentStatusArr[this.studentInfo.studentStatus]
        : this.studentInfo.studentStatus
    },
    isOr (str) {
      return str == 1 ? '是' : '否'
    },
    toTime () {
      return [
        this.$way.timestampReturnDate(
          this.studentInfo.admissionTime,
          'timeYMDHM'
        ) || '暂无',
        this.$way.timestampReturnDate(
          this.studentInfo.graduationTime,
          'timeYMDHM'
        ) || '暂无'
      ]
    }
  },
  mounted () {
    document.querySelector('.baseinfo .van-tabs__content .van-tabs__track').id =
      'baseInfoTrack'
  },
  methods: {
    changeTab () {
      if (this.activeStep == 0) {
        console.log('basic')
        document.querySelector(
          '.baseTab .van-tabs__content .van-tabs__track'
        ).id = 'baseInfoTrack'
      } else {
        document.querySelector(
          '.baseTab .van-tabs__content .van-tabs__track'
        ).id = 'ww'
      }
    },
    toastProviceField (info) {
      console.log(info, 'info')
      this.toastEvent('provice')
    },
    // 自定义刷新数据
    freshData (data) {
      console.log('data', data)
      console.log(this.infoParamList, 'infoParamList')
      // for (let i = 0; i < this.infoParamList.length; i++) {
      //   const item = this.infoParamList[i]
      //   if (item.templateId&&item.templateId==data.templateId) {
      //     item.value = data.value
      //     console.log('this.infoParamList',this.infoParamList[i]);
      //     break
      //   }
      // }
    },
    // 获取自定义参数列表
    getConfirmList () {
      this.$request.getConfirmItemList().then((res) => {
        console.log(res)
        if (res.data.code == 0) {
          this.infoParamList = res.data.data.map((item) => {
            let val = item.value || null
            const arr = [5, 9, 10]
            if (val) {
              if (item.formatType == 8) {
                val = JSON.parse(item.value)
              } else if (arr.includes(item.formatType)) {
                val = item.value.split('|')
                console.log(item, 'item', val)
              } else if (item.formatType == 6 || item.formatType == 7) {
                // val =
              }
            }
            return {
              ...item,
              value: val
            }
          })
        }
      })
    },
    onChange ({ value, selectedOptions, tabIndex }) {
      console.log(selectedOptions, 'selectedOptions')
      console.log(value, 'value', tabIndex, this.proviceData[tabIndex].value)
      if (tabIndex === 0) {
        const obj = {
          name: value
        }
        this.$request.getCitiesStu(obj).then((res) => {
          if (res.data.code == 0) {
            this.proviceData.forEach((val, key) => {
              if (val.id == selectedOptions[0].id) {
                this.proviceData[key].children = res.data.data.map((item) => {
                  return {
                    ...item,
                    text: item.name,
                    value: item.name
                  }
                })
              }
            })
          }
        })
      } else {
      }
    },
    onFinish ({ selectedOptions }) {
      this.showPicker = false
      this.studentInfo.nativePlace = selectedOptions
        .map((option) => option.text)
        .join('')
      this.nativePlace = selectedOptions.map((option) => option.text).join('/')
      console.log(this.studentInfo.nativePlace, 'this.studentInfo.nativePlace')
    },
    // 获取省份
    getProviceEvent () {
      this.$request.getProviceNew().then((res) => {
        if (res.data.code == 0) {
          this.proviceData = res.data.data.map((item) => {
            return {
              ...item,
              text: item.name,
              value: item.name,
              children: []
            }
          })
        }
      })
    },
    initProvice () {
      // 初始化籍贯
      if (this.studentInfo.nativePlace) {
        // 籍贯切割
        this.nativePlace = this.studentInfo.nativePlace
        // let arr = ['省','市','区']
        //   for (let index = 0; index < arr.length; index++) {
        //     let num = this.studentInfo.nativePlace.indexOf(arr[index])
        //     if (num!==-1) {
        //       this.studentInfo.provice= this.studentInfo.nativePlace.substring(0,num+1)
        //       this.studentInfo.city= this.studentInfo.nativePlace.substring(num+1,this.studentInfo.nativePlace.length)
        //       break
        //     }
        //   }
        //   console.log('******',this.studentInfo.provice,this.studentInfo.city);
      }
    },
    getUserData () {
      this.$request
        .getPersonInfo()
        .then((res) => {
          if (res.data.code == 0) {
            this.studentInfo = res.data.data
            this.initProvice()
            this.getConfirmList()
            this.getValKey()
            this.stuInfoCollect()
            this.formatter(this.studentInfo.confirmStatus)
          }
        })
        .catch((error) => {
          this.Error(error.response.data.msg)
        })
    },
    getUserDataEnroll () {
      this.$request
        .admitStuInfoDetail()
        .then((res) => {
          if (res.data.code == 0) {
            this.studentInfo = res.data.data
            this.initProvice()
            this.getConfirmList()
            this.getValKey()
            this.stuInfoCollect()
            this.formatter()
          }
        })
        .catch((error) => {
          this.Error(error.response.data.msg)
        })
    },
    // 获取资料采集
    stuInfoCollect () {
      this.$request
        .stuInfoCollect()
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.data.showFlag) {
              res.data.data.items.forEach((item) => {
                item.urls = item.urls ? item.urls : []
              })
              this.steps.push(
                { label: '信息采集' }
              )
            }
            this.stuCollectInfo = res.data.data
          }
        })
        .catch((error) => {
          this.Error(error.response.data.msg)
        })
    },
    // 初次渲染的表单元素，有值的禁用处理
    getValKey () {
      if (this.arrDara.length != 0) return
      for (const key in this.formInline) {
        this.formInline[key] = this.studentInfo[key]
      }
      for (const key in this.formInline) {
        if (this.formInline[key] || this.formInline[key] === 0) { this.arrDara.push(key) }
      }
    },
    firstDisabled (key) {
      if (this.studentInfo.confirmStatus == 2) {
        return true
      } else if (this.studentInfo.confirmStatus == 3) {
        return false
        // } else if (this.arrDara.includes(key)) {
        //   console.log(this.arrData,66)
        //   return true
      } else {
        return false
      }
    },
    toastEvent (name) {
      if (this.firstDisabled(name)) return
      this.pickname = name
      let arr = []
      switch (name) {
        case 'nation':
          arr = this.$common.nationData.map((item) => item.name)
          this.columns = arr
          break
        case 'politicsStatus':
          arr = Object.values(this.$common.politicsData)
          this.columns = arr
          break
        case 'provice':
          arr = Object.values(this.proviceData)
          console.log(this.proviceData, 'proviceData')
          this.columns = arr
          break
        default:
          this.columns = Object.values(this.isOrObj)
          break
      }
      console.log(this.columns)
      this.showPicker = !this.showPicker
    },
    onConfirm (value, index) {
      console.log(value, index)
      // this.value = value
      const isOrList = [
        'overseasChineseFlag',
        'specialAdministrativeRegionFlag',
        'disabilityFlag'
      ]
      if (isOrList.includes(this.pickname)) {
        this.studentInfo[this.pickname] = index
      } else this.studentInfo[this.pickname] = value
      this.showPicker = false
    },
    // 获取手机号验证码
    getVerify () {
      if (
        !this.studentInfo.phone &&
        this.studentInfo.studentConfirmPhoneFlag != 1
      ) {
        this.Error('请输入手机号码')
        return
      }
      if (
        !this.studentInfo.bindPhone &&
        this.studentInfo.studentConfirmPhoneFlag == 1
      ) {
        this.Error('请输入手机号码')
        return
      }
      const phoneSend =
        this.studentInfo.studentConfirmPhoneFlag == 1
          ? this.studentInfo.bindPhone
          : this.studentInfo.phone
      if (!this.$way.regPhoneNew(phoneSend)) {
        this.Error('手机号码格式有误')
        return
      }
      this.$request.sendMsg(phoneSend).then((res) => {
        if (res.data.code == 0) {
          this.Success(res.data.data || '发送成功')
          this.time()
        }
      })
    },
    // 倒计时
    time () {
      var wait = 60
      this.isDisabled = true
      var t = setInterval(() => {
        this.btnText = `请稍候(${wait})`
        wait--
        if (wait == -1) {
          clearInterval(t)
          this.btnText = '获取验证码'
          this.isDisabled = false
          wait = 60
        }
      }, 1000)
    },
    goBack () {
      if (this.activeStep === 0) {
        if (this.type == 1) { window.webkit.messageHandlers.getData.postMessage('close()') } else if (this.type == 2) window.Android.Close()
      } else {
        this.activeStep = this.activeStep - 1
      }
    },
    enterEvent () {
      if (this.activeStep === this.steps.length - 1) {
        this.$refs.formbtn.click()
      } else {
        this.activeStep = this.activeStep + 1
      }
    },
    // form表单检验不通过时触发
    failedEvent (errorInfo) {
      // console.log('failed', errorInfo)
      Toast.fail('基本信息有必填项未填')
    },
    submitEvent () {
      for (const key in this.formInline) {
        this.formInline[key] = this.studentInfo[key]
      }
      const objForm = JSON.parse(JSON.stringify(this.formInline))
      if (this.studentInfo.studentConfirmPhoneFlag == 1) { objForm.phone = objForm.bindPhone }
      delete objForm.bindPhone
      objForm.infos = []
      if (this.stuCollectInfo.showFlag) {
        let hasNFlag = false // 信息采集下的样例是否有未上传的
        this.stuCollectInfo.items.forEach((item) => {
          if (item.urls.length == 0) hasNFlag = true
          objForm.infos.push({
            description: item.description,
            id: item.id,
            urls: item.urls
          })
        })
        if (hasNFlag) {
          Toast.fail('采集信息有必填项未填')
          return
        }
      }
      this.infoParamList.forEach((item) => {
        if (
          item.fillFlag == 1 &&
          !(item.value || item.value == 0 || item.length > 0)
        ) {
          return Toast.fail('有输入框未填或下拉列表未选')
        }
      })
      objForm.confirmItems = (this.infoParamList || []).map((item, idx) => {
        let val = item.value
        console.log(item, 'item' + idx)
        if (item.formatType != 8 && Array.isArray(item.value)) {
          val = item.value.join('|')
        } else if (item.formatType == 8) {
          val = JSON.stringify(item.value)
        } else if (item.formatType == 6 || item.formatType == 7) {
          console.log(val, 'val', item)
          val = val.getTime()
        } else if (item.formatType > 8 && Array.isArray(item.value)) {
          let empty = 0
          this.fieldInfo.value.forEach(itemP => {
            if (!itemP && itemP != 0) {
              empty++
            }
          })
          if (empty == 3 || empty == 2) {
            val = null
          }
        }
        return {
          ...item,
          templateValue: null,
          value: val
        }
      })
      console.log(objForm, 'objForm')
      if (this.enroll) {
        this.enrollSubmit(objForm)
        return
      }
      this.$request
        .stuStatusComfirm(objForm)
        .then((res) => {
          if (res.data.code == 0) {
            this.Success(res.data.msg || '提交成功')
            this.goBack()
          } else {
            this.Error(res.data.msg)
          }
        })
        .catch((error) => {
          this.Error(error.response.data.msg)
        })
    },
    enrollSubmit (objForm) {
      this.$request
        .admitStuInfoConfirm(objForm)
        .then((res) => {
          if (res.data.code == 0) {
            this.Success(res.data.msg || '提交成功')
            this.goBack()
          } else {
            this.Error(res.data.msg)
          }
        })
        .catch((error) => {
          this.Error(error.response.data.msg)
        })
    },
    handleUploadClick (item, index) {
      if (this.studentInfo.confirmStatus == 2) return
      // this.example = item.id
      this.exampleUpIdx = index
      this.showUploadWay = !this.showUploadWay
    },
    // 上传方式选择
    onSelect (item) {
      if (item.value == 'video') {
        this.$refs.videoDom.click()
      } else if (item.value == 'image') {
        this.$refs.fileDom.click()
      }
    },
    // 图片上传
    async imgChange () {
      const inputDOM = this.$refs.fileDom
      const files = inputDOM.files[0]
      // let fileSize = files.size / 1024 / 1024
      // if(fileSize > 10) {
      //   this.Error('照片不大于10M')
      //   return
      // }
      const obj = await uploadAli.uploadOss(files, 'any')
      if (obj.excelUrl) {
        this.stuCollectInfo.items[this.exampleUpIdx].urls.push(obj.excelUrl)
      }
      this.$refs.fileDom.value = ''
    },
    // 拍照上传
    async videoChange () {
      const inputDOM = this.$refs.videoDom
      const files = inputDOM.files[0]
      const fileSize = files.size / 1024 / 1024
      if (fileSize > 10) {
        this.Error('照片不大于10M')
        return
      }
      const obj = await uploadAli.imgFileUploadOss(files, 'img')
      if (obj.excelUrl) {
        this.stuCollectInfo.items[this.exampleUpIdx].urls.push(obj.excelUrl)
      }
      this.$refs.videoDom.value = ''
    },
    isshowFile (item) {
      const val = item.urls.length < 2
      return val
    },
    // 图片预览
    previewEvent (img) {
      ImagePreview([img])
    },
    downEvent (fUrl) {
      if (this.type == 1) { window.webkit.messageHandlers.gotoDownload.postMessage(fUrl) } else if (this.type == 2) window.Android.outWeb(fUrl)
    },
    deleteEvent (index, ind) {
      this.stuCollectInfo.items[index].urls.splice(ind, 1)
    },
    formatter (value) {
      const val =
        value == 1
          ? '审核通过'
          : value == 2
            ? '待审核'
            : value == 3
              ? '审核不通过'
              : '未确认'
      this.confirmStatusShow = val
    },
    getFileName (url) {
      const arr = url.split('/')
      const index = url.lastIndexOf('.')
      const ext = url.substr(index + 1)
      const name = arr[arr.length - 1]
      if (name.length > 5) { return `${this.studentInfo.name}${name.substr(0, 5)}...${ext}` } else return `${this.studentInfo.name}${name}`
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep #baseInfoTrack {
  transform: none !important;
}
</style>
<style lang="scss">
.studentStatusConfirm {
  height: 100vh;
  overflow: scroll;
  // padding: 0 24px;
  background-color: #EDEEF2;
  .topBg {
    position: absolute;
    width: 100%;
    height: 198px;
    background: linear-gradient(180deg, #3870FF 34%, #EDEEF2 100%);
  }
  .stepper {
    position: relative;
    background: #FFFFFF;
    border-radius: 10px;
    margin: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 25px;
    .step-container {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-grow: 1;
      position: relative;
      height: 50px;
      .step {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        height: 100%;
        z-index: 1;
        .circle {
          width: 9px;
          height: 9px;
          border-radius: 50%;
          border: 4px solid #3870FF;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: white;
        }
        .endcircle {
          background: #BACDFF;
          border: none;
          width: 13px;
          height: 13px;
       }

        // .step.active .circle {
        //   width: 9px;
        //   height: 9px;
        // }

        .label {
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 15px;
          color: #333333;
        }
      }
      .lineBox {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        height: 100%;
        .line {
          height: 3px;
          background: #BACDFF;
          width: 100%;
          padding: 0 100%;
          position: absolute;
          left: -100%;
        }
        .line2 {
          width: 170%;
          padding: 0 132%;
          position: absolute;
          left: -132%;
        }
        .label {
          visibility: hidden;
        }
      }
      .lineBox.completed {
        .line {
          background: #3870FF;
        }
      }
      .topbox {
        width: 100%;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .step.completed .circle {
        border-color: #3870FF; /* 已完成的颜色 */
      }
    }
  }

  .infoModule-cont {
    padding: 14px;
    box-sizing: border-box;
    .audit-area {
      margin-bottom: 16px;
    }
    .van-form {
      overflow: hidden;
    }
    .van-cell {
      background-color: transparent;
      padding: 14px 8px;
    }
    .van-cell--required::before {
      left: 0;
    }
    .van-field--disabled {
      .van-field__label {
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 15px;
        color: #B2B2B2;
      }
      .van-field__value {
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 15px;
        color: #B2B2B2;
      }
    }
    .van-field__label {
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 15px;
      color: #333333;
    }
    .van-field__value {
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 15px;
      color: #B2B2B2;
    }
    .van-cell::after {
      left: 0;
      right: 0;
      border-color: #D2D2D2;
    }
    .formItemBox {
      background: #FFFFFF;
      border-radius: 10px;
      padding: 19px 22px;
      margin-bottom: 12px;
      .formItemTitle {
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 18px;
        color: #333333;
        line-height: 16px;
      }
      .formItemContent {

      }
    }

    .collect-item {
      background-color: #fff;
      box-sizing: border-box;
      padding: 27px 0 18px;
      border-bottom: 1px solid #efefef;
      .ci-title {
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 15px;
        color: #333;
        margin-bottom: 16px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        white-space: nowrap;
        & > span:nth-of-type(1) {
          width: 220px;
        }
        & > span:nth-of-type(2) {
          color: #4473F9;
          display: flex;
          align-items: center;
          width: 80px;
          margin-left: 10px;
          img {
            width: 14px;
            height: 14px;
            margin-right: 4px;
          }
        }
      }
      .fc-area {
        display: flex;
        gap: 24px;
      }
      .ke-show {
        position: relative;
        width: 87px;
        height: 56px;
      }
      .addImg {
        width: 87px;
        height: 56px;
        border-radius: 6px;
        position: relative;
        background: #F4F6FA;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        & > img:nth-of-type(1) {
          width: 21px;
          height: 21px;
        }
        // & > img:nth-of-type(2) {
        //   width: 32px;
        //   height: 32px;
        //   position: absolute;
        //   left: 50%;
        //   top: 50%;
        //   transform: translate(-50%, -50%);
        // }
      }
      .img-ke {
        text-align: center;
      }
      .van-image {
        width: 87px;
        height: 56px;
        border-radius: 6px;
        overflow: hidden;
      }
      .zhez {
        position: absolute;
        width: 87px;
        height: 56px;
        border-radius: 6px;
        background-color: #33333388;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 17px;
          height: 17px;
        }
        .deleteI {
          position: inherit;
          right: -5px;
          top: -5px;
        }
        .downI {
          width: 30px;
          height: 30px;
        }
      }
      .notImg {
        width: 87px;
        height: 56px;
        text-align: center;
        background-color: #f9f9f9;
        word-break: break-all;
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
        // margin: 0 auto;
        border-radius: 6px;
        font-size: 8px;
        box-sizing: border-box;
        padding: 17px 6px 10px;
        background-image: url("../../assets/img/fileIcon.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        img {
          width: 36px;
          height: 40px;
          margin-bottom: 6px;
        }
      }
      .ci-dec {
        margin-top: 15px;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 12px;
        color: #999999;
      }
    }
    .collect-tip {
      padding: 15px 0 25px;
      & > p {
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 12px;
        color: #333333;
      }
      & > p:nth-of-type(1) {
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 15px;
        color: #333333;
        margin-bottom: 15px;
      }
    }
  }
  .collect-cont {
    background-color: #fff;
  }
  .info-btn-area {
    margin: 18px 20px;
    display: flex;
    justify-content: space-between;
    button {
      width: 160px;
      height: 42px;
      border-radius: 21px;
    }
    .back {
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 19px;
      color: #3C6EF8;
      border: 1px solid #3870FF;
    }
    .next {
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 19px;
      color: #FFFFFF;
      background: linear-gradient(0deg, #3364F3, #497AFF);
    }
    // &.opacity {
    //   opacity: 0;
    //   padding: 10px 24px;
    // }
    // .info-btn {
    //   z-index: 999 !important;
    // }
    // .van-button {
    //   margin: 0 10px;
    // }
  }
}
</style>

<style>
/* 选中项样式 */
.van-picker-column__item--selected {
  transform: scale(1.5)!important;
  opacity: 1!important;
  font-weight: bold!important;
  transition: transform 0.3s, opacity 0.3s;
}

.van-picker-column__item--selected + .van-picker-column__item {
  transform: scale(1.3);
  opacity: 0.8;
  transition: transform 0.3s, opacity 0.3s;
}

.van-picker-column__item--selected + .van-picker-column__item + .van-picker-column__item {
  transform: scale(1.2);
  opacity: 0.6;
  transition: transform 0.3s, opacity 0.3s;
}

.van-picker-column__item--selected + .van-picker-column__item + .van-picker-column__item + .van-picker-column__item {
  transform: scale(1.1);
  opacity: 0.4;
  transition: transform 0.3s, opacity 0.3s;
}

/* 默认状态下非选中元素 */
.van-picker-column__item {
  transform: scale(1);
  opacity: 0.3;
  transition: transform 0.3s, opacity 0.3s;
}

</style>
